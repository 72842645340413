var render = function render(){var _vm=this,_c=_vm._self._c;return _c('inputControl',_vm._b({directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _vm.opened && _vm.close()),expression:"() => opened && close()"}]},'inputControl',_vm.propsNoValue,false),[_c('input',{attrs:{"type":"text","name":"","id":_vm._id,"placeholder":_vm.placeholder,"readonly":"","disabled":_vm.disabled},domProps:{"value":_vm.formatted(_vm.value)},on:{"click":function($event){_vm.opened = !_vm.disabled && !_vm.opened},"input":(e) => {
      _vm.$emit('input', _vm.toDate(e.target.value));
    }}}),(_vm.opened)?_c('div',{staticClass:"input-datepicker"},[_c('datepicker-component',{ref:"calendar",attrs:{"id":"datepicker-day","calId":"datepicker-day","name":"datepickerStart","value":_vm.value,"open-date":_vm.defaultValue,"highlighted":{
        dates: _vm.highlighted,
      },"disabled-dates":_vm.disabledDates,"calendar-class":"datepicker","wrapper-class":"datepicker-inline","inline":true,"monday-first":true,"language":_vm.calLang,"disabled":_vm.disabled,"disabled-date-message":_vm.disabledDateMessage},on:{"input":(v) => {
        _vm.$emit('input', v);
        _vm.opened = false;
      }}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }