<template>
  <inputControl
    v-bind="propsNoValue"
    v-click-outside="() => opened && close()"
  >
    <input
      type="text"
      name=""
      :id="_id"
      :placeholder="placeholder"
      :value="formatted(value)"
      readonly
      :disabled="disabled"
      @click="opened = !disabled && !opened"
      @input="(e) => {
        $emit('input', toDate(e.target.value));
      }"
    >
    <div
      v-if="opened"
      class="input-datepicker"
    >
      <datepicker-component
        id="datepicker-day"
        calId="datepicker-day"
        name="datepickerStart"
        :value="value"
        :open-date="defaultValue"
        :highlighted="{
          dates: highlighted,
        }"
        :disabled-dates="disabledDates"
        calendar-class="datepicker"
        wrapper-class="datepicker-inline"
        ref="calendar"
        :inline="true"
        :monday-first="true"
        :language="calLang"
        :disabled="disabled"
        :disabled-date-message="disabledDateMessage"
        @input="(v) => {
          $emit('input', v);
          opened = false;
        }"
      ></datepicker-component>
    </div>
  </inputControl>
</template>

<script>
import inputControl from '@/components/ui/inputControlUi.vue';
import inputMixin from '@/mixins/ui/inputMixin';
import {
  en, de, pl, it, fr,
} from 'vuejs-datepicker/dist/locale';

export default {
  mixins: [inputMixin],
  props: {
    defaultValue: {
      type: Date,
      default: new Date(),
    },
    value: {
      type: Date,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    disabledDates: {
      type: Object,
      default: () => {},
    },
    highlightedDates: {
      type: Array,
      default: () => [],
    },
    calendLang: {
      type: String,
      default: 'en',
    },
    disabled: Boolean,
    disabledDateMessage: String,
  },
  components: {
    inputControl,
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    },
    propsNoValue() {
      const props = { ...this.$props };
      delete props.value;
      return props;
    },
    highlighted() {
      const value = this.value ? [this.value] : [];
      return this.highlightedDates.length > 0 ? this.highlightedDates : value;
    },
    calLang() {
      return this.langs?.[this.calendLang];
    },
    langs() {
      return {
        en, de, pl, it, fr,
      };
    },
  },
  methods: {
    close() {
      /** validate, close calendar and emit value */
      const value = this.value instanceof Date ? this.value : this.toDate(this.value);
      this.$emit('input', value);
      this.opened = false;
    },
    toDate(str) {
      const momentDate = this.$moment(str?.trim(), ['DD MMM YYYY', 'D', 'DD MMM', 'DD MM', 'DD MM YYYY', 'YYYY-MM-DD'], true);
      return momentDate.isValid() ? momentDate.toDate() : undefined;
    },
    formatted(date) {
      return date ? this.$moment(date).format('DD MMM YYYY') : '';
    },
  },
};
</script>

<style lang="scss" scoped>
.input-datepicker {
  position: absolute;
  padding-top: 1rem;
  margin-top: 1.5rem;
  z-index:1070;
  width:250px;
  transition: background .3s ease-in-out;
}
@media only screen and (max-width: 768px) {
  .input-datepicker {
    position: fixed;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
